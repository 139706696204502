::-webkit-scrollbar {
  background: transparentize(#dadce0, .8);
  height: $scrollbar-width;
  width: $scrollbar-width;
}

::-webkit-scrollbar-thumb {
  border: none;
  box-shadow: none;
  background: #dadce0;
  //border-radius: 8px;
  min-height: 40px;
}

:hover::-webkit-scrollbar-thumb {
  background: #bdc1c6
}

::-webkit-scrollbar-thumb:hover {
  background: #bdc1c6
}

::-webkit-scrollbar-thumb:active {
  background: #80868b
}
